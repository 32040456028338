import React from "react";
import Button from "@common/Button/Button";
import { MAIN_BUTTON } from "@constants";
import arrowIcon from "@images/common/ic-arrow-right.svg";
import {
    Container,
    ButtonContainer,
    TextContainer,
    ContentContainer,
} from "./PropositionSection.style";
import { Title, Description } from "../CommonStyles/CommonStyles.style";

const PropositionSection = ({
    text,
    description,
    buttonText,
    buttonNavigation,
}) => (
    <Container>
        <ContentContainer>
            <TextContainer>
                <Title>{text}</Title>
                <Description>{description}</Description>
            </TextContainer>
            <ButtonContainer href={buttonNavigation}>
                <Button
                    text={buttonText}
                    type={MAIN_BUTTON}
                    bannerButtonIconPath={arrowIcon}
                />
            </ButtonContainer>
        </ContentContainer>
    </Container>
);

export default PropositionSection;
