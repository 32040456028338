import firstBenefit from "@images/common/benefit1.svg";
import thirdBenefit from "@images/common/benefit3.svg";
import hat from "@images/common/hat.svg";
import tax from "@images/common/tax.svg";

export const BENEFIT_LIST_SECTION_TITLE= "Why choose Impressit?";

export const LIST_ITEMS = [
    {
        title: "Full commitment",
        icon: null,
        description: "At Impressit, we are equally dedicated to the success of your business and committed to supporting you throughout the entire development process.",
        iconFromMarkupPath: firstBenefit,
    },
    {
        title: "Time & costs savings",
        icon: null,
        iconFromMarkupPath: tax,
        description: "Partnering with Impressit for quality development is crucial in avoiding future issues. Our experienced developers create top-notch products with comprehensive maintenance support for the lifetime of your software. Don't settle for less; prioritize security, functionality, and up-to-date technology with Impressit.",
    },
    {
        title: "Reduced risks",
        icon: null,
        iconFromMarkupPath: thirdBenefit,
        description: "While it may be tempting to hire freelance developers who offer lower hourly rates, it could end up costing you more in terms of security concerns, incomplete documentation, and subpar code.",
    },
    {
        title: "Experience",
        icon: null,
        iconFromMarkupPath: hat,
        description: "It is a top priority for us to ensure that you are updated on all aspects of your software. We are delighted to provide our experience and insight to support you as you go through the production journey.",
    },
];